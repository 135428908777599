<template>
  <div class="content">
    <div class="content__title content__title--party">
      <div class="content__title content__title--party content__title--start">
        <router-link
          :to="{ name: 'events' }"
          class="button button--light button--no-indent button--small"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z"
                  fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z"
                  fill="#2B93E7"/>
          </svg>
        </router-link>
        <h1 class="title title--big title--theme">Просмотр мероприятия</h1>
      </div>
    </div>
    <form class="form">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label for="name">Название мероприятия<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.name.$error }">
              <input
                id="name"
                type="text"
                class="form-control__input"
                name="name"
                placeholder="Введите название мероприятия"
                v-model.trim="form.name"
                readonly
              />
            </div>
            <div class="form-group__error" v-if="$v.form.name.$dirty && !$v.form.name.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label>Состояние<sup>*</sup></label>
            <div class="form-group__party">
              <label class="radio radio--readonly">
                <div class="radio__text">Запланировано</div>
                <input type="radio" name="status_ev" v-model.number="form.status_ev" value="1" />
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio radio--readonly">
                <div class="radio__text">Подтверждено</div>
                <input type="radio" name="status_ev" v-model.number="form.status_ev" value="2" />
                <div class="radio__radiomark"></div>
              </label>
            </div>
            <div class="form-group__error" v-if="$v.form.status_ev.$dirty && !$v.form.status_ev.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label>Тип<sup>*</sup></label>
            <div class="form-group__party">
              <label class="radio radio--readonly">
                <div class="radio__text">Онлайн</div>
                <input type="radio" name="type_of" v-model.number="form.type_of" value="1" />
                <div class="radio__radiomark"></div>
              </label>
              <label class="radio radio--readonly">
                <div class="radio__text">Офлайн</div>
                <input type="radio" name="type_of" v-model.number="form.type_of" value="2" />
                <div class="radio__radiomark"></div>
              </label>
            </div>
            <div class="form-group__error" v-if="$v.form.status_ev.$dirty && !$v.form.status_ev.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label>Уровень проведения мероприятия<sup>*</sup></label>
            <div class="form-group__party form-group__party--triple">
              <label
                v-for="level in level_options"
                :key="level.id"
                class="radio radio--readonly"
              >
                <div class="radio__text">{{ level.name }}</div>
                <input type="radio" name="level" v-model.number="form.level" :value="level.id" />
                <div class="radio__radiomark"></div>
              </label>
            </div>
            <div class="form-group__error" v-if="$v.form.status_ev.$dirty && !$v.form.status_ev.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label for="date_start">Дата начала проведения<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.date_start.$error }">
              <masked-input
                v-model="form.date_start"
                name="date_start"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_start"
                autocomplete="off"
                readonly
              />
            </div>
            <div class="form-group__error" v-if="$v.form.date_start.$dirty && !$v.form.date_start.required">
              Обязательное поле
            </div>
          </div>
          <div v-if="form.date_end" class="form-group">
            <label for="date_end">Дата окончания проведения</label>
            <div class="form-control">
              <masked-input
                v-model="form.date_end"
                name="date_end"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_end"
                autocomplete="off"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="place">Место проведения<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.place.$error }">
              <input
                id="place"
                type="text"
                class="form-control__input"
                name="place"
                placeholder="Введите место проведения"
                v-model.trim="form.place"
                readonly
              />
            </div>
            <div class="form-group__error" v-if="$v.form.place.$dirty && !$v.form.place.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group">
            <label for="description">Короткий анонс<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.description.$error }">
              <textarea
                id="description"
                name="description"
                class="form-control__textarea"
                v-model.trim="form.description"
                placeholder="Введите анонс"
                readonly
              >
              </textarea>
            </div>
            <div class="form-group__error" v-if="$v.form.description.$dirty && !$v.form.description.required">
              Обязательное поле
            </div>
          </div>
          <div class="form-group" v-if="form.file_url">
            <label>Положение о мероприятии</label>
            <FakeUploader
              @deleteFakePassport="onRemoveFile"
              :photoUrl="form.file_url"
              :readonly="true"
            />
          </div>
        </div>
        <div class="form__part">
          <h2 class="title title--indent">Контактное лицо</h2>
          <div class="form-group">
            <label for="place">ФИО<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.full_name.$error }">
              <input
                id="full_name"
                type="text"
                class="form-control__input"
                name="full_name"
                placeholder="Введите контактное лицо"
                v-model.trim="form.full_name"
                readonly
              />
            </div>
            <div class="form-group__error" v-if="$v.form.full_name.$dirty && !$v.form.full_name.required">
              Обязательное поле
            </div>
          </div>
          <div v-if="form.phone" class="form-group">
            <label>Телефон</label>
            <vue-tel-input
              v-model="phone_value"
              defaultCountry="RU"
              mode="international"
              type="tel"
              disabled
              class="phonepicker phonepicker--readonly"
              :class="{
                'phonepicker--error': !is_phone_valid,
              }"
              @validate="onValidatePhone"
              :inputOptions="phone_picker_options"
              validCharactersOnly
            >
              <template slot="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                  <path d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z" fill="#2B93E7"></path>
                </svg>
              </template>
            </vue-tel-input>
            <div class="form-group__error" v-if="!is_phone_valid">Недопустимое значение для номера телефона</div>
          </div>
          <div class="form-group">
            <label for="email">Электронная почта<sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.email.$error }">
              <input
                id="email"
                type="email"
                name="email"
                class="form-control__input"
                placeholder="Введите электронную почту"
                v-model.trim="form.email"
                @change="$v.form.email.$touch()"
                readonly
              />
            </div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле</div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.email">
              Введите правильный формат почты
            </div>
          </div>
          <div v-if="form.news_link.length > 1" class="form-group">
            <label>Ссылки на новости о проведении мероприятия</label>
            <div class="form-group" v-for="(item, index) in form.news_link" :key="index">
              <div class="form-control">
                <input
                  :id="`news_link${index}`"
                  type="url"
                  class="form-control__input"
                  :name="`news_link${index}`"
                  placeholder="https://example.com"
                  v-model.trim="item.link"
                  readonly
                />
              </div>
            </div>
          </div>
          <div v-if="form.photo_link" class="form-group">
            <label for="photo_link">Ссылка на фоторепортаж, где видно количество участников, использование символики РСМ</label>
            <div class="form-control">
              <input
                id="photo_link"
                type="url"
                class="form-control__input"
                name="photo_link"
                placeholder="https://example.com"
                v-model.trim="form.photo_link"
                readonly
              />
            </div>
          </div>
          <div v-if="userRole === 'admin'" class="form-group">
            <label>Статус<sup>*</sup></label>
            <v-select
              v-model="form.status"
              :reduce="status => status.id"
              :searchable="false"
              :clearable="false"
              :options="status_options"
              :get-option-label="getLabel"
              placeholder="Выберите статус"
              class="select"
              :class="{'select--error': $v.form.status.$error}"
              readonly
            >
              <template slot="open-indicator">
                <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                  <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
                </svg>
              </template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">
                  {{ option.name }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.name }}
                </div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.status.$dirty && !$v.form.status.required">
              Обязательное поле
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {required, email, requiredIf} from 'vuelidate/lib/validators'
import MaskedInput from 'vue-masked-input'
import FakeUploader from '@/components/FakeUploader'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

export default {
  name: 'EventPlanEdit',
  mixins: [ validationMixin ],
  components: {
    MaskedInput,
    FakeUploader,
    VueTelInput,
  },
  validations: {
    form: {
      type_of: { required },
      status_ev: { required },
      level: { required },
      name: { required },
      date_start: { required },
      place: { required },
      description: { required },
      full_name: { required },
      email: { required, email },
      status: {
        required: requiredIf(function(){
          return this.userRole === 'admin'
        })
      },
    },
  },
  data() {
    return {
      form: {
        status_ev: 1,
        type_of: 1,
        level: 1,
        news_link: [
          {
            link: ''
          }
        ],
      },
      manage: {},
      phone_value: '',
      is_phone_valid: true,
      phone_picker_options: {
        placeholder: 'Введите свой номер телефона'
      },
      file_post_url: `${process.env.VUE_APP_API_URL}files/`,
      loading: false,
      show_sidebar: false,
      region_options: [],
      status_options: [],
      level_options: [],
    }
  },
  computed: {
    userRole() {
      return this.$store.state.userRole
    },
    userRegion() {
      return this.$store.state.organization
    },
  },
  mounted() {
    if (this.userRegion) this.form.region_org = this.userRegion
    this.$store.dispatch('regOrg/GET_QUERY')
      .then(response => {this.region_options = response.data})
    this.$store.dispatch('status/GET_LIST')
      .then(response => {this.status_options = response.data.results})
    this.$store.dispatch('eventPlan/GET_LEVEL')
      .then(response => {this.level_options = response.data.results})
    this.fetchForm()
  },
  methods: {
    fetchForm () {
      this.$store.dispatch('calendar/GET_EVENTS_DATA', this.$route.params.id)
        .then(response => {
          this.form = {...this.form, ...response.data}
          let { status, comment_tech, comment_cancel } = response.data
          this.manage = { status, comment_tech, comment_cancel }
          this.phone_value = response.data.phone ?? ''
          if (!response.data.news_link) {
            this.form.news_link = [
              {
                link: ''
              }
            ]
          }
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка получения данных'
          })
        })
    },
    onCheckForm(saveType = '') {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        if (saveType) this.form.status = saveType
        this.sendForm()
      }
    },
    sendForm() {
      this.loading = true
      this.$store.dispatch('eventPlan/PATCH_DATA', { id: this.$route.params.id, data: this.form })
        .then(() => {
          this.loading = false
          this.$router.push({ name: 'event-plan' })
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Изменения сохранены.'
          });
        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка сохранения изменений.'
          });
        });
    },
    sendManage() {
      this.form = {...this.form, ...this.manage}
      this.$store.dispatch('eventPlan/PATCH_DATA', { id: this.$route.params.id, data: this.manage })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Изменения сохранены.'
          })

        })
        .catch(() => {
          this.loading = false
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка сохранения данных.'
          })
        })
    },
    getLabel (option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    getFileHash(value) {
      this.form.file = value
    },
    onRemoveFile() {
      this.form.file = ''
      this.form.file_url = ''
    },
    onValidatePhone(number) {
      this.is_phone_valid = number.valid
      if (number.valid && number.number !== '') {
        this.form.phone = number.number
      } else {
        this.form.phone = ''
      }
      if (!number.hasOwnProperty('number')) {
        this.is_phone_valid = true
      }
    },
    onCloneLink() {
      this.form.news_link.push({ link: '' });
    },
    onDeleteLink(index) {
      this.form.news_link.splice(index, 1);
    },
    openSidebar() {
      this.show_sidebar = true
    }
  }
}
</script>
